// import { Margin, usePDF } from 'react-to-pdf';
// import React, { useEffect } from 'react';
// import SecondResultScreenComponent from './SecondScreen';
// import generatePDF from 'react-to-pdf';

// export default function FirstScreenResult(props) {
//   const {
//     ExamData,
//     setReload,
//     choose, setChoose,
//     AnswerArray, setAnswerArray,
//     ResultData, Rank
//   } = props;
//   const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
//   const [QuestionsSolved, setQuestionsSolved] = React.useState(0);
//   const [correctAnswer, setcorrectAnswer] = React.useState(0);
//   const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(false);
//   const [ArrayIndex, setArrayIndex] = React.useState("");
//   const [QuestionsData, setQuestionsData] = React.useState(JSON.parse(ExamData.meta.exam_question_block_text));
//   const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
//   const [QuestionsEditData, setQuestionsEditData] = React.useState(JSON.parse(ExamData.meta.exam_question_block_text)["item-0"]);
//   const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");
//   const CalculateQuestionSolved = () => {
//     var correct = 0;
//     var solved = 0;
//     for (let i = 0; i < AnswerArray.length; i++) {
//       if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen) {
//         correct++
//       }
//       if (AnswerArray[i].solved == 1)
//         solved++
//       setQuestionsSolved(solved)
//       setcorrectAnswer(correct)
//     }
//   }
//   useEffect(() => {
//     CalculateQuestionSolved()
//   }, [])

//   return (
//     <div>
//       {/* <div onClick={() => toPDF()}>Download PDF</div> */}
//       <div style={{padding:"50px",backgroundColor:"#f00"}} onClick={() => generatePDF(targetRef, {
//         filename: 'page.pdf', method: 'open',
//         page: {
//           margin: 20,
//           format: 'A4',
//        },
//       })}>Do1wnload PDF</div>

      
//       <div
//        style={{ display: "none" }}
//       >
//         <div
//           ref={targetRef}
//         >
//           <div style={{
//             zIndex: 1, position: "absolute", height: "100%",
//           }}>
//            
//               style={{
//                 width: "100vw",
//                 height: "100vh",
//                 objectFit: "contain",
//               }}
//             />
//           </div>

//           <section style={{
//             pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
//           }}
//           >
//             <div className="row" style={{ marginLeft: "20px" }}>
//               <div className="col-3" style={{ padding: "10px", marginRight: "5px", border: "2px solid #0f0" }}   >
//                 <span style={{
//                   display: "inline-block",
//                   border: "1px solid black",
//                   borderRadius: "20px", width: "30px",
//                   height: "30px",
//                   fontSize: "20px", fontWeight: "bold",
//                 }}>
//                   <i class="fa fa-check"></i>
//                 </span>
//                 &nbsp; Correct Answer Choosen
//               </div>
//               <div className="col-3" style={{ padding: "10px", marginRight: "5px", border: "2px solid #f00" }}   >
//                 <span style={{
//                   display: "inline-block",
//                   border: "1px solid black",
//                   borderRadius: "20px", width: "30px",
//                   height: "30px",
//                   fontSize: "20px", fontWeight: "bold",
//                 }}>
//                   <i class="fa fa-check"></i>
//                 </span>
//                 &nbsp; Wrong Answer Choosen
//               </div>
//               <div className="col-3" style={{ padding: "10px", marginRight: "5px", border: "2px solid #f00" }}   >
//                 <span style={{
//                   display: "inline-block",
//                   border: "1px solid black",
//                   borderRadius: "20px", width: "30px",
//                   height: "30px",
//                   fontSize: "20px", fontWeight: "bold",
//                 }}>
//                   &nbsp;1
//                 </span>
//                 &nbsp; Correct Answer Not Choosen
//               </div>
//             </div>

//             <div style={{ margin: "20px", }}>
//               {/* <div ref={targetRef}> */}
//               <div
//                 // ref={StudentExportRef5}
//                 style={{ margin: "20px" }}>
//                 <div id="content">
//                   <div className="col-12 form-view mb-3 ques-print"
//                     style={{ padding: "20px" }}
//                   >
//                     <div style={{ overflowX: "scroll", }}>
//                       {Object.values(QuestionsData).map((oneQuestionData, index) => {

//                         const lower = oneQuestionData.question.split(" ")[0].split("~")[0].replace(/\D/g, "");
//                         return (
//                           <section key={index} style={{
//                             pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
//                           }}
//                             onClick={() => {
//                               setQuestionsEditData(oneQuestionData)
//                               setQuestionsEditKey(Object.keys(QuestionsData)[index])
//                               setQuestionsEditMode(true)
//                             }}
//                           >
//                             <div style={{
//                               zIndex: 1, position: "absolute", height: "100%",
//                             }}>
//                            
//                                 style={{
//                                   width: "100vw",
//                                   height: "100vh",
//                                   objectFit: "contain",
//                                   opacity: 0.1
//                                 }}
//                               />
//                             </div>
//                             <div style={{ zIndex: 10, position: "relative" }}>

//                               <div className="row"
//                                 style={{
//                                   // border: "1px solid #999",
//                                   borderRadius: "5px",
//                                   // margin: "5px",
//                                   padding: 0
//                                 }}>
//                                 <div style={{ padding: "0px 0 0 0" }}>
//                                   {lower == oneQuestionData.question_number &&
//                                     <h3 style={{ fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
//                                       {oneQuestionData.question}
//                                     </h3>
//                                   }
//                                   <h3 style={{ fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
//                                     {oneQuestionData.question_number}.
//                                   </h3>
//                                 </div>
//                                 {oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
//                                   <div className={"col-6"} >
//                                     <div>

//                                       <div className="question-description ">
//                                         {oneQuestionData.question_description_type == "text" &&
//                                           <div style={{
//                                             fontSize: "20px",
//                                             padding: "0 0 5px", margin: "0 40px"
//                                           }}>
//                                             <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
//                                           </div>
//                                         }
//                                         {oneQuestionData.question_description_type == "image" &&
//                                           <>
//                                             <div style={{ padding: "2px", textAlign: "center", }} >
//                                               <img
//                                                 src={oneQuestionData.question_description}
//                                                 style={{
//                                                   height: "150px",
//                                                   // width: "392px",
//                                                   objectFit: 'contain',
//                                                   padding: "2px",
//                                                   border: "0.5px solid #999",
//                                                 }}
//                                               />
//                                             </div>
//                                           </>
//                                         }
//                                         {oneQuestionData.question_description_type == "audio" &&
//                                           <div>
//                                             <div style={{ cursor: "pointer" }} onClick={() => {
//                                             }}>
//                                               <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
//                                                 style={{ width: "30px", height: "30px" }}
//                                               />
//                                             </div>
//                                           </div>
//                                         }
//                                       </div>

//                                       {oneQuestionData.question_media !== "" &&
//                                         <div className="question-description col-10">
//                                           {oneQuestionData.question_media_type == "text" &&
//                                             <div style={{
//                                               border: "0.5px solid #999",
//                                               padding: "5px ",
//                                               justifyContent: 'center',
//                                               display: "flex"
//                                             }}>
//                                               <div style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
//                                             </div>
//                                           }
//                                           {oneQuestionData.question_media_type == "image" &&
//                                             <div style={{ textAlign: "center" }}>
//                                               <img
//                                                 src={oneQuestionData.question_media}
//                                                 style={{
//                                                   height: "150px",
//                                                   // width: "392px",
//                                                   padding: "2px",
//                                                   objectFit: 'contain',
//                                                   border: "0.5px solid #999"
//                                                 }}
//                                               />
//                                             </div>
//                                           }
//                                           {oneQuestionData.question_media_type == "audio" &&
//                                             <div style={{ margin: "0 40px" }}>
//                                               <div style={{ cursor: "pointer" }} >
//                                                 <div style={{ cursor: "pointer" }}>
//                                                   <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
//                                                     style={{ width: "30px", height: "30px" }}
//                                                   />
//                                                 </div>
//                                               </div>
//                                             </div>
//                                           }
//                                         </div>
//                                       }
//                                     </div>
//                                   </div>
//                                   :
//                                   <div className="col-md-12" >
//                                     <div style={{}}>
//                                       {/* <div style={{ padding: "3px 0 0 0" }}>
//                                       {lower == oneQuestionData.question_number &&
//                                         <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
//                                           {oneQuestionData.question}
//                                         </h3>
//                                       }
//                                       <h3 style={{ fontSize: '18px', fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
//                                         {oneQuestionData.question_number}.
//                                       </h3>
//                                     </div> */}
//                                       <div className="question-description ">
//                                         {oneQuestionData.question_description_type == "text" &&
//                                           <div style={{ fontSize: "15px", padding: "0 0 10px", margin: "0 40px" }}>
//                                             <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
//                                           </div>
//                                         }
//                                         {oneQuestionData.question_description_type == "image" &&
//                                           <>
//                                             <div style={{ padding: "2px", textAlign: "center", }}>
//                                               <img
//                                                 src={oneQuestionData.question_description}
//                                                 style={{ height: "70px", objectFit: 'contain', padding: "2px", border: "0.5px solid #000", }}
//                                               />
//                                             </div>
//                                           </>
//                                         }
//                                         {oneQuestionData.question_description_type == "audio" &&
//                                           <a href={oneQuestionData.question_description} download={`${ExamData.title.rendered}-${oneQuestionData.question_number}`}>
//                                             <div style={{ margin: "0 40px" }}>
//                                               <div style={{ cursor: "pointer" }}>
//                                                 <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
//                                                   style={{ width: "30px", height: "30px" }}
//                                                 />
//                                               </div>
//                                             </div>
//                                           </a>
//                                         }
//                                       </div>
//                                       {oneQuestionData.question_media !== "" &&
//                                         <div className="question-description col-10" style={{ margin: "0 40px" }}>
//                                           {oneQuestionData.question_media_type == "text" &&
//                                             <div style={{ border: "0.5px solid #000", padding: "5px ", justifyContent: 'center', display: "flex" }}>
//                                               <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
//                                             </div>
//                                           }

//                                           {oneQuestionData.question_media_type == "image" &&
//                                             <div style={{ textAlign: "center" }}  >
//                                               <img
//                                                 src={oneQuestionData.question_media}
//                                                 style={{ height: "70px", padding: "2px", objectFit: 'contain', border: "0.5px solid #000" }}
//                                               />
//                                             </div>
//                                           }
//                                           {oneQuestionData.question_media_type == "audio" &&
//                                             <a href={oneQuestionData.question_media} download={`${ExamData.title.rendered}-${oneQuestionData.question_number}`}>
//                                               <div style={{ cursor: "pointer" }}>
//                                                 <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
//                                                   style={{ width: "30px", height: "30px" }}
//                                                 />
//                                               </div>
//                                             </a>
//                                           }
//                                         </div>
//                                       }
//                                     </div>
//                                   </div>
//                                 }
//                                 <div
//                                   className={oneQuestionData.answer_media_type == "image" ?
//                                     "col-md-12" :
//                                     oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
//                                       "col-6" : "col-md-12"
//                                   }
//                                   style={{ padding: "20px", margin: "auto" }}>
//                                   <div
//                                     className={oneQuestionData.answer_media_type == "image" ? "row" :
//                                       oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
//                                         "" : "row"}
//                                     style={{
//                                       justifyContent: "center", alignContent: "center", alignItems: "center", height: "100%",
//                                       margin: oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ? 0 : "0 50px"
//                                     }} >
//                                     <AnswerBlock5 index2={index}
//                                       oneQuestionData={oneQuestionData} AnswerArray={AnswerArray}
//                                       index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
//                                     <AnswerBlock5 index2={index} AnswerArray={AnswerArray} oneQuestionData={oneQuestionData} index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
//                                     <AnswerBlock5 index2={index} AnswerArray={AnswerArray} oneQuestionData={oneQuestionData} index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
//                                     <AnswerBlock5 index2={index} AnswerArray={AnswerArray} oneQuestionData={oneQuestionData} index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           </section>
//                         )
//                       })}
//                     </div>
//                     {/* <div style={{ zIndex: 10, position: "relative" }}>
//                     <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
//                       <br />
//                       <u><b>Answers</b></u>
//                       <br />
//                       <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
//                         <div className="row" >
//                           {Object.values(QuestionsData).map((oneQuestionData, index) => (
//                             <section key={index} style={{ width: "10%", border: "1px solid black", }}>
//                               <div className="row">
//                                 <div className="col-6" style={{ borderRight: "0.5px solid black", textAlign: "center", padding: "0px 0" }} >{index + 1}</div>
//                                 <div className="col-6" style={{ padding: "0px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
//                               </div>
//                             </section>
//                           ))}
//                         </div>
//                       </div>
//                     </div> */}
//                     {/* </div> */}
//                   </div>
//                 </div>
//               </div>
//               {/* </div> */}
//             </div>

//           </section>

//           {/* })} */}
//         </div>
//       </div>

//     </div>
//   )
// }

// // import ThridScreenComponent from './ThridScreen';
// // import  usePDF  from 'react-to-pdf';

// // export default function FirstScreenResult(props) {




// //   return (
// //     <div
// //     //  style={{ border: "1px solid #aaa", padding: "30px", }}
// //     >
// //       <div style={{ padding: "0 10px" }}>
// //         <div className="sign-in-button-4"
// //           onClick={() => {
// //             StudentExportPrint5()
// //           }}
// //         >
// //           Download Complete Report
// //         </div>
// //       </div>
// //       <div onClick={() => toPDF()}>Download PDF</div>

// //     </div >
// //   )
// // }

// const AnswerBlock5 = ({ index2, index, DataType, DataValue, oneQuestionData, AnswerArray }) => {
//   return (
//     <div className={DataType == "text" ?
//       (oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image") ?
//         "col-12" :
//         "col-6"
//       :
//       "col-6"}
//       style={{
//         display: 'flex', padding: "5px 10px",
//         // border: "3px solid",
//         border: !AnswerArray[index2].solved == 1 ?
//           AnswerArray[index2].correct_answer == `option ${index}` ?
//             "3px solid #0f0" :
//             "0px solid #eee"
//           :
//           AnswerArray[index2].answerChoosen == `option ${index}` ?
//             AnswerArray[index2].correct_answer == `option ${index}` ?
//               "3px solid #0f0"
//               : "3px solid #f00"
//             :
//             AnswerArray[index2].correct_answer == `option ${index}` ?
//               "3px solid #0f0" :
//               "0px solid #eee"
//       }}>
//       <div style={{ marginRight: "5px", cursor: "pointer" }}  >
//         <div style={{
//           border: "1px solid black",
//           borderRadius: "20px", width: "30px",
//           height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
//           fontSize: "20px", fontWeight: "bold"
//         }}>
//           {AnswerArray[index2].answerChoosen == `option ${index}` ?
//             <i class="fa fa-check"></i>
//             :
//             <>
//               &nbsp;{index}&nbsp;
//             </>
//           }
//         </div>
//       </div>
//       <div className="passive-option2">
//         <div
//         //  style={{ fontSize: "18px" }}
//         >
//           {/* {AnswerArray[index2].correct_answer} {AnswerArray[index2].answerChoosen} {index2} {`option ${index}`} */}
//           {DataType == "text" && `${DataValue}`}</div>
//         {DataType == "image" && <img src={DataValue} style={{ height: "170px", border: "0.5px solid #000" }} />}
//         {DataType == "audio" &&
//           <div>
//             <a href={DataValue} target="_blank">
//               <div style={{ cursor: "pointer" }} onClick={() => { }}>
//                 <div>
//                   <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
//                     style={{ width: "20px", height: "20px" }}
//                   />
//                 </div>
//               </div>
//             </a>
//           </div>
//         }
//       </div>
//     </div>
//   )
// }

import React, { useEffect } from 'react';
import SecondResultScreenComponent from './SecondScreen';
// import ThridScreenComponent from './ThridScreen';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
export default function FirstScreenResult(props) {
  const {
    ExamData,
    setReload,
    choose, setChoose,
    AnswerArray, setAnswerArray,
    ResultData, Rank
  } = props;
  const [QuestionsSolved, setQuestionsSolved] = React.useState(0);
  const [correctAnswer, setcorrectAnswer] = React.useState(0);
  const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(false);
  const [ArrayIndex, setArrayIndex] = React.useState("");
  const [QuestionsData, setQuestionsData] = React.useState(JSON.parse(ExamData.meta.exam_question_block_text));
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(JSON.parse(ExamData.meta.exam_question_block_text)["item-0"]);
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");

  const CalculateQuestionSolved = () => {
    var correct = 0;
    var solved = 0;
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen) {
        correct++
      }
      if (AnswerArray[i].solved == 1)
        solved++
      setQuestionsSolved(solved)
      setcorrectAnswer(correct)
      StudentExportPrint5()
    }
  }
  useEffect(() => {
    CalculateQuestionSolved()

  }, [])
  const StudentExportRef5 = React.useRef(null);
  const StudentExportPrint5 = useReactToPrint({
    content: () => StudentExportRef5.current,
    pageStyle: { margin: "10px" },
  })
  return (
    <div style={{ border: "1px solid #aaa", padding: "30px", }}>
      {/* Questions Solved: {QuestionsSolved} */}
      <h1>{ExamData.title.rendered}</h1>
      <b>Exam Date: {ResultData.meta.exam_date_results}</b>
      <div style={{ padding: "10px" }}>
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#66fc03"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Correct_Answer
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#f00"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Wrong_Answer
        <span style={{
          border: "2px solid black",
          margin: "10px", width: "20px", height: "20px", backgroundColor: "#f7e686"
        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Unsolved_Question

      </div>

      {
        QuestionsEditMode ?
          <div >
            <div className="row center row-data">
              <div className="col-md-6 row center" >
                {Object.keys(QuestionsData).map((item, index) => {
                  if (index < 20)
                    return (
                      <div key={index} className="col-2 m-1 p-2 center"
                        style={{
                          backgroundColor: !AnswerArray[index].solved == 1 ?
                            "#f7e686" :
                            AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
                              "#66fc03" :
                              "#f00"
                          ,
                          fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                          border: "2px solid black",
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setQuestionsEditData(QuestionsData[item])
                          setQuestionsEditKey(item)
                          setQuestionsEditMode(false)
                          setArrayIndex(index)
                        }}
                      >
                        {QuestionsData[item].question_number}
                      </div>
                    )
                })}
              </div>
              <div className="col-md-6 row">
                {Object.keys(QuestionsData).map((item, index) => {
                  if (index >= 20)
                    return (
                      <div key={index} className="col-2 m-1 p-2 center"
                        style={{
                          backgroundColor: !AnswerArray[index].solved == 1 ?
                            "#f7e686" :
                            AnswerArray[index].correct_answer == AnswerArray[index].answerChoosen ?
                              "#66fc03" :
                              "#f00"
                          ,
                          fontWeight: QuestionsEditKey == item ? "bold" : "normal",
                          border: "2px solid black",
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setQuestionsEditData(QuestionsData[item])
                          setQuestionsEditKey(item)
                          setQuestionsEditMode(false)
                          setArrayIndex(index)
                        }}
                      >
                        {QuestionsData[item].question_number}
                      </div>
                    )
                })}
              </div>
              {/* <div className="row">
            <div className="col-lg-4 col-12" />
            <div className="col-lg-4 col-12 center">
              <button className="sign-in-button-1 col-lg-8 col-7"
                onClick={() => {
                  setChoose(2)
                  // setTimingStarted(true)
                  // SubmitExam()
                }}
              >
                Submit and Finish Exam
              </button>
            </div>
          </div> */}

            </div>
            <div className="row row-data">
              <div className="col-8">Total Questions</div>
              <div className="col-4">{Object.keys(QuestionsData).length}</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Total Attempt</div>
              <div className="col-4">{QuestionsSolved}</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Total Correct</div>
              <div className="col-4">{correctAnswer}</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Percentage Obtained</div>
              <div className="col-4">{(correctAnswer / AnswerArray.length * 100).toFixed(2)}%</div>
            </div>
            <div className="row row-data">
              <div className="col-8">Rank</div>
              <div className="col-4">{Rank + 1}</div>
            </div>
            {/* {JSON.stringify(ResultData.meta.exam_total_results)} */}
          </div>
          :
          <div>
            {!QuestionsEditMode1 ?
              <SecondResultScreenComponent
                keyValue={QuestionsEditKey}
                oneQuestionData={QuestionsEditData}
                AllQuestionData={QuestionsData}
                setAllQuestionData={setQuestionsData}
                AnswerArray={AnswerArray}
                ArrayIndex={ArrayIndex}
                setQuestionsSolved={setQuestionsSolved}
                QuestionsSolved={QuestionsSolved}
              />
              :
              <div style={{ minHeight: "50vh" }}>
              </div>
            }

            <div className="row mx-5 mt-3">
              <div className="col-md-4">
                {ArrayIndex !== 0 &&
                  <div className="col-12 previous-question" style={{ cursor: "pointer" }}
                    onClick={() => {
                      setQuestionsEditMode1(true)
                      setTimeout(() => {
                        setQuestionsEditMode1(false)
                      }, 0)
                      setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex - 1]])
                      setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex - 1])
                      setArrayIndex(ArrayIndex - 1)
                    }}
                  >
                    <button className="sign-in-button-1 shadow">
                      <i className="fa fa-chevron-left" />&nbsp; Previous Question
                    </button>
                  </div>
                }
              </div>
              <div className="col-md-4 " style={{ textAlign: 'center', cursor: "pointer" }}
                onClick={() => {
                  setQuestionsEditMode(true)
                }}
              >
                <button className="sign-in-button-1 shadow">
                  <i className="fa fa-list" />&nbsp;   Question List
                </button>
              </div>
              {ArrayIndex !== Object.keys(QuestionsData).length - 1 &&
                <div className="col-md-4 question-list" style={{ cursor: "pointer" }}
                  onClick={() => {
                    setQuestionsEditMode1(true)
                    setTimeout(() => {
                      setQuestionsEditMode1(false)
                    }, 0)
                    setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
                    setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex + 1])
                    setArrayIndex(ArrayIndex + 1)
                  }
                  }
                >
                  <button className="sign-in-button-1 shadow">
                    Next Question&nbsp;  <i className="fa fa-chevron-right" />
                  </button>
                </div>

              }
            </div>
          </div>
      }

      <div className=" col-3" style={{ padding: "0 10px" }}>
        <div className="sign-in-button-4"
          onClick={() => {
            StudentExportPrint5()
          }}
        >
          Download Complete Report
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          ref={StudentExportRef5} >

          {/* {Projects2.map((item, index) => {
          const QuestionsData = JSON.parse(item.meta.exam_question_block_text)
          const Title = item.title.rendered;
          return ( */}
          <div style={{
            zIndex: 1, position: "absolute", height: "100%",
          }}>
            <img src="https://api.azzamyhakwon.com/wp-content/uploads/2023/11/watermark.jpeg"
              style={{
                width: "100vw",
                height: "100vh",
                objectFit: "contain",
                // opacity: 0.1
              }}
            />
          </div>

          <section style={{
            pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
          }}
          >
            <div className="row" style={{ marginLeft: "20px" }}>
              <div className="col-3" style={{ padding: "10px", marginRight: "5px", border: "2px solid #0f0" }}   >
                <span style={{
                  display: "inline-block",
                  border: "1px solid black",
                  borderRadius: "20px", width: "30px",
                  height: "30px",
                  fontSize: "20px", fontWeight: "bold",
                }}>
                  <i class="fa fa-check"></i>
                </span>
                &nbsp; Correct Answer Choosen
              </div>
              <div className="col-3" style={{ padding: "10px", marginRight: "5px", border: "2px solid #f00" }}   >
                <span style={{
                  display: "inline-block",
                  border: "1px solid black",
                  borderRadius: "20px", width: "30px",
                  height: "30px",
                  fontSize: "20px", fontWeight: "bold",
                }}>
                  <i class="fa fa-check"></i>
                </span>
                &nbsp; Wrong Answer Choosen
              </div>
              <div className="col-3" style={{ padding: "10px", marginRight: "5px", border: "2px solid #f00" }}   >
                <span style={{
                  display: "inline-block",
                  border: "1px solid black",
                  borderRadius: "20px", width: "30px",
                  height: "30px",
                  fontSize: "20px", fontWeight: "bold",
                }}>
                  &nbsp;1
                </span>
                &nbsp; Correct Answer Not Choosen
              </div>
            </div>

            <div style={{ margin: "20px", }}>
              {/* <div ref={targetRef}> */}
              <div
                // ref={StudentExportRef5}
                style={{ margin: "20px" }}>
                <div id="content">
                  <div className="col-12 form-view mb-3 ques-print"
                    style={{ padding: "20px" }}
                  >
                    <div style={{ overflowX: "scroll", }}>
                      {Object.values(QuestionsData).map((oneQuestionData, index) => {

                        const lower = oneQuestionData.question.split(" ")[0].split("~")[0].replace(/\D/g, "");
                        return (
                          <section key={index} style={{
                            pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                          }}
                            onClick={() => {
                              setQuestionsEditData(oneQuestionData)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            <div style={{
                              zIndex: 1, position: "absolute", height: "100%",
                            }}>
                              <img src="https://api.lpkhankukbogosippo.com/wp-content/uploads/2023/10/watermark.jpeg"
                                style={{
                                  width: "100vw",
                                  height: "100vh",
                                  objectFit: "contain",
                                  opacity: 0.1
                                }}
                              />
                            </div>
                            <div style={{ zIndex: 10, position: "relative" }}>

                              <div className="row"
                                style={{
                                  // border: "1px solid #999",
                                  borderRadius: "5px",
                                  // margin: "5px",
                                  padding: 0
                                }}>
                                <div style={{ padding: "0px 0 0 0" }}>
                                  {lower == oneQuestionData.question_number &&
                                    <h3 style={{ fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                      {oneQuestionData.question}
                                    </h3>
                                  }
                                  <h3 style={{ fontSize: "22px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                    {oneQuestionData.question_number}.
                                  </h3>
                                </div>
                                {oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                  <div className={"col-6"} >
                                    <div>

                                      <div className="question-description ">
                                        {oneQuestionData.question_description_type == "text" &&
                                          <div style={{
                                            fontSize: "20px",
                                            padding: "0 0 5px", margin: "0 40px"
                                          }}>
                                            <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        }
                                        {oneQuestionData.question_description_type == "image" &&
                                          <>
                                            <div style={{ padding: "2px", textAlign: "center", }} >
                                              <img
                                                src={oneQuestionData.question_description}
                                                style={{
                                                  height: "150px",
                                                  // width: "392px",
                                                  objectFit: 'contain',
                                                  padding: "2px",
                                                  border: "0.5px solid #999",
                                                }}
                                              />
                                            </div>
                                          </>
                                        }
                                        {oneQuestionData.question_description_type == "audio" &&
                                          <div>
                                            <div style={{ cursor: "pointer" }} onClick={() => {
                                            }}>
                                              <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                style={{ width: "30px", height: "30px" }}
                                              />
                                            </div>
                                          </div>
                                        }
                                      </div>

                                      {oneQuestionData.question_media !== "" &&
                                        <div className="question-description col-10">
                                          {oneQuestionData.question_media_type == "text" &&
                                            <div style={{
                                              border: "0.5px solid #999",
                                              padding: "5px ",
                                              justifyContent: 'center',
                                              display: "flex"
                                            }}>
                                              <div style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                            </div>
                                          }
                                          {oneQuestionData.question_media_type == "image" &&
                                            <div style={{ textAlign: "center" }}>
                                              <img
                                                src={oneQuestionData.question_media}
                                                style={{
                                                  height: "150px",
                                                  // width: "392px",
                                                  padding: "2px",
                                                  objectFit: 'contain',
                                                  border: "0.5px solid #999"
                                                }}
                                              />
                                            </div>
                                          }
                                          {oneQuestionData.question_media_type == "audio" &&
                                            <div style={{ margin: "0 40px" }}>
                                              <div style={{ cursor: "pointer" }} >
                                                <div style={{ cursor: "pointer" }}>
                                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                    style={{ width: "30px", height: "30px" }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  :
                                  <div className="col-md-12" >
                                    <div style={{}}>
                                      {/* <div style={{ padding: "3px 0 0 0" }}>
                                      {lower == oneQuestionData.question_number &&
                                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                          {oneQuestionData.question}
                                        </h3>
                                      }
                                      <h3 style={{ fontSize: '18px', fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                        {oneQuestionData.question_number}.
                                      </h3>
                                    </div> */}
                                      <div className="question-description ">
                                        {oneQuestionData.question_description_type == "text" &&
                                          <div style={{ fontSize: "15px", padding: "0 0 10px", margin: "0 40px" }}>
                                            <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                          </div>
                                        }
                                        {oneQuestionData.question_description_type == "image" &&
                                          <>
                                            <div style={{ padding: "2px", textAlign: "center", }}>
                                              <img
                                                src={oneQuestionData.question_description}
                                                style={{ height: "70px", objectFit: 'contain', padding: "2px", border: "0.5px solid #000", }}
                                              />
                                            </div>
                                          </>
                                        }
                                        {oneQuestionData.question_description_type == "audio" &&
                                          <a href={oneQuestionData.question_description} download={`${ExamData.title.rendered}-${oneQuestionData.question_number}`}>
                                            <div style={{ margin: "0 40px" }}>
                                              <div style={{ cursor: "pointer" }}>
                                                <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                  style={{ width: "30px", height: "30px" }}
                                                />
                                              </div>
                                            </div>
                                          </a>
                                        }
                                      </div>
                                      {oneQuestionData.question_media !== "" &&
                                        <div className="question-description col-10" style={{ margin: "0 40px" }}>
                                          {oneQuestionData.question_media_type == "text" &&
                                            <div style={{ border: "0.5px solid #000", padding: "5px ", justifyContent: 'center', display: "flex" }}>
                                              <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                            </div>
                                          }

                                          {oneQuestionData.question_media_type == "image" &&
                                            <div style={{ textAlign: "center" }}  >
                                              <img
                                                src={oneQuestionData.question_media}
                                                style={{ height: "70px", padding: "2px", objectFit: 'contain', border: "0.5px solid #000" }}
                                              />
                                            </div>
                                          }
                                          {oneQuestionData.question_media_type == "audio" &&
                                            <a href={oneQuestionData.question_media} download={`${ExamData.title.rendered}-${oneQuestionData.question_number}`}>
                                              <div style={{ cursor: "pointer" }}>
                                                <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                                  style={{ width: "30px", height: "30px" }}
                                                />
                                              </div>
                                            </a>
                                          }
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                                <div
                                  className={oneQuestionData.answer_media_type == "image" ?
                                    "col-md-12" :
                                    oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                      "col-6" : "col-md-12"
                                  }
                                  style={{ padding: "20px", margin: "auto" }}>
                                  <div
                                    className={oneQuestionData.answer_media_type == "image" ? "row" :
                                      oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                        "" : "row"}
                                    style={{
                                      justifyContent: "center", alignContent: "center", alignItems: "center", height: "100%",
                                      margin: oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ? 0 : "0 50px"
                                    }} >
                                    <AnswerBlock5 index2={index}
                                      oneQuestionData={oneQuestionData} AnswerArray={AnswerArray}
                                      index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                    <AnswerBlock5 index2={index} AnswerArray={AnswerArray} oneQuestionData={oneQuestionData} index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                    <AnswerBlock5 index2={index} AnswerArray={AnswerArray} oneQuestionData={oneQuestionData} index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                    <AnswerBlock5 index2={index} AnswerArray={AnswerArray} oneQuestionData={oneQuestionData} index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        )
                      })}
                    </div>
                    {/* <div style={{ zIndex: 10, position: "relative" }}>
                    <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                      <br />
                      <u><b>Answers</b></u>
                      <br />
                      <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
                        <div className="row" >
                          {Object.values(QuestionsData).map((oneQuestionData, index) => (
                            <section key={index} style={{ width: "10%", border: "1px solid black", }}>
                              <div className="row">
                                <div className="col-6" style={{ borderRight: "0.5px solid black", textAlign: "center", padding: "0px 0" }} >{index + 1}</div>
                                <div className="col-6" style={{ padding: "0px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
                              </div>
                            </section>
                          ))}
                        </div>
                      </div>
                    </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>

          </section>

          {/* })} */}
        </div>
      </div>
    </div >
  )
}

const AnswerBlock5 = ({ index2, index, DataType, DataValue, oneQuestionData, AnswerArray }) => {
  return (
    <div className={DataType == "text" ?
      (oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image") ?
        "col-12" :
        "col-6"
      :
      "col-6"}
      style={{
        display: 'flex', padding: "5px 10px",
        // border: "3px solid",
        border: !AnswerArray[index2].solved == 1 ?
          AnswerArray[index2].correct_answer == `option ${index}` ?
            "3px solid #0f0" :
            "0px solid #eee"
          :
          AnswerArray[index2].answerChoosen == `option ${index}` ?
            AnswerArray[index2].correct_answer == `option ${index}` ?
              "3px solid #0f0"
              : "3px solid #f00"
            :
            AnswerArray[index2].correct_answer == `option ${index}` ?
              "3px solid #0f0" :
              "0px solid #eee"
      }}>
      <div style={{ marginRight: "5px", cursor: "pointer" }}  >
        <div style={{
          border: "1px solid black",
          borderRadius: "20px", width: "30px",
          height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "20px", fontWeight: "bold"
        }}>
          {AnswerArray[index2].answerChoosen == `option ${index}` ?
            <i class="fa fa-check"></i>
            :
            <>
              &nbsp;{index}&nbsp;
            </>
          }
        </div>
      </div>
      <div className="passive-option2">
        <div
        //  style={{ fontSize: "18px" }}
        >
          {/* {AnswerArray[index2].correct_answer} {AnswerArray[index2].answerChoosen} {index2} {`option ${index}`} */}
          {DataType == "text" && `${DataValue}`}</div>
        {DataType == "image" && <img src={DataValue} style={{ height: "170px", border: "0.5px solid #000" }} />}
        {DataType == "audio" &&
          <div>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>
  )
}